







































































































































































































































































































































































































































































































































































































































import {
    Component,
    Vue,
	Watch
} from 'vue-property-decorator';
import {
    getComponent
} from '@/utils/helpers';
import wellTestModule from '@/store/modules/wellTestModule';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import Multiselect from 'vue-multiselect';
import { Debounce } from 'vue-debounce-decorator';
import { DateTime } from 'luxon';

import workspaceModule from '@/store/modules/workspaceModule';
import AWS from 'aws-sdk';

@Component({
    components: {
        AppLayout: () => getComponent('common/AppLayout'),
        RigSchedule: () => getComponent('schedule/RigSchedule'),
		ComponentDetails: () => getComponent('schedule/ComponentDetails'),
		AddSchedule: () => getComponent('schedule/AddSchedule'),
		Multiselect,
    },
})

export default class ScheduleV2 extends mixins() {

	selectedColumnType = "DETAILS"
	userSelectionVal = ''
	yourValue = ''

	// NEEDED
	refreshResponseDataKey = 0

	addJobChecklistPopup = false

	addJobIsLoading = false

	// NEEDED
	addJobGroupSelected = ''

	// Loading views
    dataLoading = false


	popupBackground = false

	editGroupPopupInnerShowing = false

  	checklistTemporaryText = ''

	addJobResponseData = {}

	showCreationError = ''


  templateDetails = [
	  {
		  type: "Fields",
		  values: [
	
		  ]
	  },{
		  type: "Rules",
		  values: [
			  
		  ]
	  }
  ]




	get activeComponent() {
		return scheduleModule.activeComponent
	}

	get activeColumns() {
		return scheduleModule.activeComponentResponseColumns
	}




	didSelectUserFromDropdown(custom_id) {
		// @ts-ignore
		this.addJobResponseData[custom_id].Response = this.userSelectionVal.value
	}

  selectAddViewOption(columns) {

	var field_values: any[] = []
	field_values.push({
		title: "Add new field",
		icon_type: "ADD",
		column_type: ""
	})
	for (var x = 0; x < columns.length; x++) {
		field_values.push({
			title: columns[x].ColumnName,
			icon: this.getColumnTypeIcon(columns[x].ColumnType),
			icon_type: columns[x].ColumnType == "WELL" ? "WELL" : columns[x].ColumnType == "RIG" ? "RIG" : "MATERIAL",
			column_type: columns[x].ColumnType,
			custom_name: columns[x].ColumnName
		})
	}
	  this.templateDetails = [
		  {
			  type: "Fields",
			  // @ts-ignore
			  values: field_values
		  }
	  ]
  }






	addJobRefreshTable() {
		this.refreshResponseDataKey = this.refreshResponseDataKey + 1
	}


	// NEEDED
	addJobDidSelectGroup() {
		this.addJobSetupPage()

	}

	addJobRemoveChecklistItem(customID, checklistIndex) {
		this.addJobResponseData[customID].Response.splice(checklistIndex, 1);
		this.refreshResponseDataKey = this.refreshResponseDataKey + 1
	}


	addJobSetupPage() {


		var columns = this.addJobColumns()

		// @ts-ignore
		for (var x = 0; x < columns.length; x++) {
			// @ts-ignore
			if (columns[x].ColumnType == "WELL") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: ''
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "USER") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: ''
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "VENDOR") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: {
						"PhoneNumber": '',
						"Email": '',
						"FullName": ''
					}
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "CHECKLIST") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: []
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "FILE") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: null
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "TESTERS") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: []
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "TASQ_DATE_RANGE") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: []
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "COST") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: ''
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "TAGS") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: []
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "TEXT") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: ''
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "DATE") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: ''
				}
			// @ts-ignore
			} else if (columns[x].ColumnType == "PROCEDURE") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: null
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "EMAIL") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: ''
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "PHONE_NUMBER") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: ''
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "START_DATE") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: ''
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "DUE_DATE") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: ''
				}
			// @ts-ignore
			} else if (columns[x].ColumnType  == "STATUS") {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: ''
				}
			} else {
				// @ts-ignore
				this.addJobResponseData[columns[x].CustomID] = {
					// @ts-ignore
					...columns[x],
					Response: ''
				}
			}
		}
		this.refreshResponseDataKey = this.refreshResponseDataKey + 1
		
	}

	// NEEDED
	addNewJobHideClicked() {
		// this.addNewJobPopupInnerShowing = false
		// this.popupBackground = false
		this.$emit("hide-add-new-job-clicked")
		
	}



  addJobDidEnterChecklistItem(customID, e) {
	if ((e.key === 'Enter' || e.keyCode === 13) && this.checklistTemporaryText != '' && this.checklistTemporaryText != ' ') {
		var newChecklist = {
			// @ts-ignore
			value: this.checklistTemporaryText,
			checked: false
		}
			// Do something
			this.addJobResponseData[customID].Response = [newChecklist].concat(this.addJobResponseData[customID].Response)
			// @ts-ignore
			this.checklistTemporaryText = ''
	}
  }

  addJobShowChecklistPopup() {
	  this.addJobChecklistPopup = true
  }

  addJobHideChecklistPopup() {
	  this.addJobChecklistPopup = false
  }

  getColumnTypeIcon(column_type) {
	  if (column_type == "WELL") {
		  return "task"
	  } else if (column_type == "USER") {
		  return "person"
	  } else if (column_type == "VENDOR") {
		  return "engineering"
	  } else if (column_type == "CHECKLIST") {
		  return "checklist"
	  } else if (column_type == "FILE") {
		  return "picture_as_pdf"
	  } else if (column_type == "TESTERS") {
		  return "quiz"
	  } else if (column_type == "TASQ_DATE_RANGE") {
		  return "date_range"
	  } else if (column_type == "COST") {
		  return "attach_money"
	  } else if (column_type == "TAGS") {
		  return "tag"
	  } else if (column_type == "TEXT") {
		  return "format_size"
	  } else if (column_type == "DATE") {
		  return "event"
	  } else if (column_type == "PROCEDURE") {
		  return "task"
	  } else if (column_type == "EMAIL") {
		  return "email"
	  } else if (column_type == "PHONE_NUMBER") {
		  return "phone"
	  } else if (column_type == "STATUS") {
		  return "task_alt"
	  } else {
		  return "task"
	  }
  }




  get availableUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
    }));
  }


  addJobColumns() {

	  if (this.addJobGroupSelected == '' || this.addJobGroupSelected == null) {
		  return
	  }


	var groupSelected;
	// @ts-ignore
	  for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
		  // @ts-ignore
		  if (workspaceModule.activeTable.Groups[x].GroupName == this.addJobGroupSelected) {
			  // @ts-ignore
			  groupSelected = workspaceModule.activeTable.Groups[x]
			  console.log("[x] Testing Column Error - Setting groupSelected")
		  }
	  }
	  var columns = groupSelected.Columns
	  return columns

  }

	// NEEDED
  get groupNames() {
	  var results = []
	  // @ts-ignore
	  for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
		  // @ts-ignore
		  results.push(workspaceModule.activeTable.Groups[x].GroupName)
	  }
	  return results
  }



  async addJob() {
	  // @ts-ignore
		this.addJobIsLoading = true
		var groupSelected = null
		// @ts-ignore
		for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
			// @ts-ignore
			if (workspaceModule.activeTable.Groups[x].GroupName == this.addJobGroupSelected) {
				// @ts-ignore
				groupSelected = workspaceModule.activeTable.Groups[x]
			}
		}

		var error_msg = ''
		
		var start_date = null
		var due_date = null
		var columns: any[] = []
		for (const [key, value] of Object.entries(this.addJobResponseData)) {
			
			            // @ts-ignore
            if (value["RequiredOnJobCreation"]) {
                
                // @ts-ignore
                if (value["Response"] == '' || value["Response"] == null || value["Response"] == undefined || value["Response"] == [] || value["Response"] == {}) {
                    console.log("[x] Did not fill out all required fields")

					if (error_msg != '') {
						// @ts-ignore
						error_msg += ', "' + value["CustomName"] + '"'
					} else {
						// @ts-ignore
						error_msg += '"' + value["CustomName"] + '"'
					}


                }
				// @ts-ignore
				if (value["ColumnType"] == "START_DATE") {
					// @ts-ignore
					start_date = value["Response"]
				}
				// @ts-ignore
				if (value["ColumnType"] == "DUE_DATE") {
					// @ts-ignore
					due_date = value["Response"]
				}
            }
			
			// @ts-ignore
			if (value["ColumnType"] == "WELL") {
				// @ts-ignore
				value["Response"] = value["Response"]["wellName"]
			}
			columns.push(value)
		}





		if (error_msg != '') {
			error_msg = "Error: " + error_msg + " fields are required."
			this.addJobIsLoading = false
			this.showCreationError = error_msg
			return
		}

		// @ts-ignore
		var start_date_obj = new Date(start_date)
		// @ts-ignore
		var due_date_obj = new Date(due_date)
		if (due_date_obj.getTime() <= start_date_obj.getTime()) {
			error_msg = "Error: Start date must be greater than the end date."
			this.addJobIsLoading = false
			this.showCreationError = error_msg	
			return
		}
		

		await scheduleModule.postNewGroupItem({
			username: accountModule.user.email.toLowerCase(),
			// @ts-ignore
			workspace_id: workspaceModule.activeWorkspace.ID,
			// @ts-ignore
			table_id: workspaceModule.activeTable.ID,
			// @ts-ignore
			group_id: groupSelected.ID,
			columns: JSON.stringify(columns)
		})
		// @ts-ignore
		this.addJobIsLoading = false
		this.addNewJobHideClicked()
		window.location.reload();

	}


    get enabledWells() {
        return assetsModule.enabledWells;
    }

  

    async created() {

    }





	




}




